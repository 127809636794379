// export const url = {
//     site: "",
//     websocket: "http://localhost:8070/",
// };

export async function fetcher<JSON = any>(
    input: RequestInfo,
    init?: RequestInit
): Promise<JSON> {
    const res = await fetch(input, init);
    return res.json();
}

export const url = {
    site : "https://plateforme-staging.reverto.fr",
    websocket : "https://plateforme-staging.reverto.fr"
}
